import { useTracker } from "./util/Tracker";
import { 마켓봄_로고_Mobile } from "./마켓봄_로고_Mobile";
import { asx } from "./util/applyStyle";
import styles from "./헤더_Mobile.module.css";
import { 헤더_서비스소개_Mobile } from "./헤더_서비스소개_Mobile";
import { 헤더_로그인_Mobile } from "./헤더_로그인_Mobile";

const as = asx(styles);
export const 헤더_Mobile = () => {
  const tracker = useTracker();
  return (
    <div className={as("header")}>
      <마켓봄_로고_Mobile />
      <div className={as("gnb")}>
        <ul>
          <li>
            <헤더_서비스소개_Mobile />
          </li>
        </ul>
      </div>

      <div className={as("sign-area")}>
        <헤더_로그인_Mobile />
        <a
          href={"/features"}
          className={as("btn btn-join touch")}
          onClick={() => {
            tracker.trackingClick({
              depth1: "GNB",
              depth2: "도입문의",
            });
          }}
        >
          도입 문의
        </a>
      </div>
    </div>
  );
};
