import Link from "next/link";
import 유투브_이미지 from "/public/assets/mobile/images/icon/icon_youtube1.svg";
import 블로그_이미지 from "/public/assets/mobile/images/icon/icon_blog1.svg";
import 플레이스토어_이미지 from "/public/assets/mobile/images/btn/btn_googleplay1.svg";
import 애플스토어_이미지 from "/public/assets/mobile/images/btn/btn_appstore1.svg";
import { useContext } from "react";
import { 회사정보Context } from "./데이터/회사정보";
import { CSR } from "./CSR";
import { 링크 } from "./데이터/링크";

export const 푸터_Mobile = () => {
  const 회사정보 = useContext(회사정보Context);
  return (
    <div id="footer">
      <h2 className="tit">{회사정보.회사명}</h2>
      <div className="copyright">
        <p>
          <span>대표이사 : {회사정보.대표이사}</span>
          <CSR>
            <span>사업자등록번호 : {회사정보.사업자등록번호}</span>
          </CSR>
          <br />
          <span>통신판매업신고 : {회사정보.통신판매업신고번호}</span>
          <br />
          <CSR>
            <span>개인정보담당자 : {회사정보.개인정보담당자}</span>
          </CSR>
          <br />
          <span>
            사업장 주소 : {회사정보.주소.건물주소}, {회사정보.주소.상세주소}
          </span>
          <br />
          <CSR>
            <span>대표번호 : {회사정보.대표번호}</span>
          </CSR>
          <span>{회사정보.이메일}</span>
          <br />
        </p>
        <p>
          <span>
            (주)마켓보로는 통신판매업의 당사자가 아닙니다. 따라서 (주)마켓보로는
            상품거래정보 및 거래에 대한 책임을 지지 않습니다.
          </span>
        </p>
        <p>
          <span>Copyright©marketboro Co., Ltd. All Rights Reserved.</span>
        </p>
      </div>
      <div className="sns-app">
        <div className="sns">
          <Link
            href={링크.Blog}
            className="btn-blog"
            style={{
              background: `url(${블로그_이미지.src}) no-repeat center top`,
            }}
          >
            Blog
          </Link>
          <Link
            href={링크.Youtube}
            className="btn-youtube"
            style={{
              background: `url(${유투브_이미지.src}) no-repeat center top`,
            }}
          >
            Youtube
          </Link>
        </div>
        <div className="app-download">
          <Link
            href={링크.거래채앱_구글플레이}
            className="btn-googleplay"
            style={{
              background: `url(${플레이스토어_이미지.src}) no-repeat center top`,
            }}
          >
            googleplay
          </Link>
          <Link
            href={링크.거래채앱_앱스토어}
            className="btn-appstore"
            style={{
              background: `url(${애플스토어_이미지.src}) no-repeat center top`,
            }}
          >
            appstore
          </Link>
        </div>
      </div>
    </div>
  );
};
