import Link from "next/link";
import styles from "./헤더_Desktop.module.css";
import { useTracker } from "./util/Tracker";

export const 헤더_회원가입_Desktop2 = () => {
  const tracker = useTracker();

  return (
    <Link
      href={"/features"}
      className={styles["demoButton"]}
      onClick={() => {
        tracker.trackingClick({
          depth1: "GNB",
          depth2: "도입문의",
        });
      }}
    >
      도입 문의
    </Link>
  );
};
