import Link from "next/link";
import { useEffect, useState } from "react";
import { 링크 } from "./데이터/링크";
import { pipe, O, S, F } from "@mobily/ts-belt";
import { match } from "ts-pattern";
import { asx } from "./util/applyStyle";
import styles from "./헤더_Mobile.module.css";
const as = asx(styles);
export const 헤더_서비스소개_Mobile = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  useEffect(() => {
    const html = pipe(document.querySelector("html"), O.fromNullable);

    const clickEffect = (e: MouseEvent) => {
      const className = pipe(
        e.target,
        O.fromNullable,
        O.flatMap((target) => (target as HTMLElement)["className"] as string),
        O.getWithDefault<string>("")
      );

      match(className)
        .when(S.includes(as("btn-toggle services")), F.ignore)
        .otherwise(() => {
          setShowMenu(false);
        });
    };

    pipe(
      html,
      O.tap((html: HTMLHtmlElement) => {
        html.addEventListener("click", clickEffect);
      })
    );
    return () => {
      pipe(
        html,
        O.tap((html) => {
          html.removeEventListener("click", clickEffect);
        })
      );
    };
  }, [showMenu]);

  return (
    <>
      <button
        className={as(`btn-toggle services touch ${showMenu ? "on" : ""}`)}
        style={{
          color: showMenu ? "#e95a0c" : "#333",
        }}
        onClick={() => {
          setShowMenu(!showMenu);
        }}
        type="button"
      >
        서비스 소개
      </button>
      <div
        className={as("depth2")}
        style={{
          display: showMenu ? "block" : "none",
        }}
      >
        <ul>
          <li>
            <Link href={링크.마켓봄_자세히보기} className="touch">
              마켓봄
            </Link>
          </li>
          <li>
            <Link href={링크.마켓봄도매_자세히보기} className="touch">
              마켓봄 도매
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};
