import { useContext } from "react";
import { GrowthBookCTX, getValue } from "./util/GrowthBookCTX";
import { pipe } from "@mobily/ts-belt";

import { 헤더_Desktop2 } from "./헤더_Desktop2";

import { match } from "ts-pattern";
import { 헤더_Desktop3 } from "./헤더_Desktop3";

export const 헤더_DesktopAB = () => {
  const featureValue = useContext(GrowthBookCTX);
  const miniatureLanding = pipe(featureValue, getValue("miniature"));

  return match(miniatureLanding)
    .with(false, () => <헤더_Desktop2 />)
    .with(true, () => <헤더_Desktop3 />)
    .exhaustive();
};
