import { A, S, pipe } from "@mobily/ts-belt";

export type Style = {
  readonly [key: string]: string;
};

export const asx = (style: Style) => (className: string) =>
  pipe(
    className,
    S.split(" "),
    A.map((x) => style[x]),
    A.join(" ")
  );
