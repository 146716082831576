import { useContext } from "react";
import { GrowthBookCTX, getValue } from "./util/GrowthBookCTX";
import { pipe } from "@mobily/ts-belt";

import { 헤더_Mobile } from "./헤더_Mobile";

export const 헤더_MobileAB = () => {
  const featureValue = useContext(GrowthBookCTX);
  const simplifiedRegister = pipe(
    featureValue,
    getValue("simplified-register")
  );

  return simplifiedRegister ? <헤더_Mobile /> : <헤더_Mobile />;
};
