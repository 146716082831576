import Link from "next/link";
import styles from "./마켓봄_로고_Mobile.module.css";
import logoImageMobile from "/public/assets/mobile/images/logo/logo1.svg";

import Image from "next/image";
export const 마켓봄_로고_Mobile = () => {
  return (
    <Link href="/" className={styles["logoImage"]}>
      <Image src={logoImageMobile} alt="마켓봄" width={50} height={16} />
    </Link>
  );
};
