import { useEffect, useState } from "react";
import { useTracker } from "./util/Tracker";

import { 링크 } from "./데이터/링크";
import { F, O, S, pipe } from "@mobily/ts-belt";
import { match } from "ts-pattern";
import { asx } from "./util/applyStyle";
import styles from "./헤더_Mobile.module.css";

const as = asx(styles);

export const 헤더_로그인_Mobile = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const tracker = useTracker();

  useEffect(() => {
    const html = pipe(document.querySelector("html"), O.fromNullable);

    const clickEffect = (e: MouseEvent) => {
      const className = pipe(
        e.target,
        O.fromNullable,
        O.flatMap((target) => (target as HTMLElement)["className"] as string),
        O.getWithDefault<string>("")
      );

      match(className)
        .when(S.includes(as("btn-toggle btn btn-login")), F.ignore)
        .otherwise(() => {
          setShowMenu(false);
        });
    };

    pipe(
      html,
      O.tap((html: HTMLHtmlElement) => {
        html.addEventListener("click", clickEffect);
      })
    );
    return () => {
      pipe(
        html,
        O.tap((html) => {
          html.removeEventListener("click", clickEffect);
        })
      );
    };
  }, [showMenu]);

  return (
    <div className={as("btn-login-wrap")}>
      <button
        type="button"
        className={as(`btn-toggle btn btn-login touch ${showMenu ? "on" : ""}`)}
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        로그인
      </button>
      <div
        className={as("login-detail")}
        style={{ display: showMenu ? "block" : "none" }}
      >
        <a
          href={링크.마켓봄_유통사}
          className={as("touch")}
          onClick={() =>
            tracker.trackingClick({
              depth1: "GNB",
              depth2: "로그인",
              depth3: "유통사",
            })
          }
        >
          유통사(매입처)
        </a>
        <a
          href={링크.마켓봄_거래처}
          className={as("touch")}
          onClick={() =>
            tracker.trackingClick({
              depth1: "GNB",
              depth2: "로그인",
              depth3: "식당",
            })
          }
        >
          거래처(식당)
        </a>
        <a
          href={링크.마켓봄도매}
          className={as("touch")}
          onClick={() =>
            tracker.trackingClick({
              depth1: "GNB",
              depth2: "로그인",
              depth3: "도매",
            })
          }
        >
          마켓봄 도매
        </a>
      </div>
    </div>
  );
};
